.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.version{
  background-color: #282c34;
  align-items: right;
  justify-content: right;
  font-size: calc(1vh + 5px);
  color: rgb(194, 114, 23);
  text-align: right;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2em + 2vmin);
  color: white;
}

@viewport {
  width: device-width;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.phoneNumber{
  color: #61dafb;
}

.fadingText {
  position: relative;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 1em;
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #fff, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

.loading:after {
  content: '.';
  animation: dots 2s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: turquoise;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 turquoise,
      .5em 0 0 rgba(0,0,0,0);}
  80%{
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 turquoise;} 
  100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 50 white;}}
